#contact-section {
  background: url("/screens/Home/assets/contact-bg.svg") no-repeat;
  background-position: center bottom;
  background-size: contain;
  padding-top: 6rem;
  padding-bottom: 6rem;

  .contact-title {
    font-size: 60px;
    max-width: 490px;
    margin: auto;
  }

  .contact-email-container {
    border: 1px solid rgba(255, 255, 255, 0.7);
    background-color: rgba(255, 255, 255, 0.3);
    height: 4rem;
    width: 50%;
    min-width: fit-content;
  }

  @media only screen and (max-width: 600px) {
    .contact-title {
      font-size: 3rem;
      margin: auto;
    }
  }
}
