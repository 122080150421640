#header {
  position: fixed;
  height: 100px;
  width: 100vw;
  z-index: 100;
  display: flex;
  align-content: center;
  .bar.mobile {
    display: flex;
    align-content: center;
    .logo-link {
      margin-left: 2rem;
      align-self: center;
    }
    .menu-btn {
      margin-right: 2rem;
      align-self: center;
    }
  }
  .bar {
    width: 100%;
    transition: background-color 0.3s ease-out;
    .ant-menu-horizontal {
      border-bottom: none !important;
    }
    .theme-selector-btn {
      width: 32px;
      height: 32px;
      padding: 2.4px 0;
      vertical-align: text-top;
    }
    .language-selector-btn {
      vertical-align: text-top;
    }
    > .ant-space-item {
      &:not(:nth-child(2)) {
        padding: 0rem 2rem;
      }

      &:last-child {
        flex: 1;
      }
    }
    .navigation-bar {
      flex-wrap: nowrap !important;
      height: 100%;
      margin: auto;
      max-width: 1280px;
    }
  }
  #system-status-detail-container {
    padding: 2rem;
    .center-indicator {
      text-align: center;
    }
    min-width: 350px;
    text-align: center;
  }
}

.helper-menu {
  ul {
    li.ant-dropdown-menu-item {
      padding: 0.5rem 1rem;
    }
  }
}

#main-menu-drawer {
  #system-status-detail-container {
    padding: 1rem;
    .center-indicator {
      text-align: center;
    }
    text-align: center;
    min-width: 350px;
    text-align: justify;
  }

  .theme-selector-btn {
    width: 32px;
    height: 32px;
    padding: 2.4px 0;
    vertical-align: text-top;
  }

  .language-selector-btn {
    padding: 0px;
  }
}

.mobile-menu-title {
  flex-direction: column;
  width: 20vw;
  min-width: 300px;
  .mobile-menu-title-top {
    width: 100%;
  }
  .mobile-menu-title-bottom {
    width: 100%;
  }
}

.mobile-navigator {
  // margin-left: 2rem;
  // @media only screen and (max-width: 960px) {
  //   margin-left: 1rem;
  // }
}

.assets-btn {
  cursor: pointer;
  vertical-align: -webkit-baseline-middle;
}

.wallet-connect-btn {
  padding: 0.7rem 1rem;
  box-sizing: border-box;
  border-radius: 3rem;
  cursor: pointer;
}

#connect-wallet-modal {
  width: 320px;
  max-width: 100%;

  .connect-wallet-title {
    font-size: 1.5rem;
    font-weight: 200;
  }

  .wallet-address-container {
    height: 3rem;
  }
}

#klip-qr-container {
  transition: all 0.5s linear;
}

.header.white-accent {
  .bar:not(.header-opaque) {
    .ant-typography {
      color: white !important;
    }
    .anticon {
      color: white !important;
    }

    .connect-wallet-btn {
      border-color: #fff !important;
      background-color: transparent !important;
      background: transparent !important;
      > span.ant-typography {
        strong {
          color: #ffff !important;
        }
      }
    }
  }
}

.header.small-device-header {
  .bar:not(.header-opaque) {
    .ant-typography {
      color: white !important;
    }
    .anticon {
      color: white !important;
    }

    .connect-wallet-btn {
      border-color: #fff !important;
      background-color: transparent !important;
      background: transparent !important;
      > span.ant-typography {
        strong {
          color: #ffff !important;
        }
      }
    }
  }
}
