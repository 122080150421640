/** Styles for the 403 Page **/
.permission_denied {
  display: flex !important;
  background: #24344c !important;
  width: 100vw;
  height: 100vh;
  margin: 0px !important;

  .rrb-logo {
    margin: 4rem 0rem;
    width: 100%;
  }

  #tsparticles {
    width: 100vw;
    height: 100vh;
    margin: 0px !important;
    position: fixed !important;
    animation: flyIn 0.5s ease-out;
  }

  @keyframes flyIn {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 0.4;
      transform: scale(1);
    }
  }

  a {
    text-decoration: none;
  }

  .denied__wrapper {
    max-width: 390px;
    width: 100%;
    height: 390px;
    display: block;
    margin: 0 auto;
    position: relative;
    margin-top: 8vh;
    text-align: center;
  }

  h1 {
    text-align: center;
    color: #fff;
    font-family: "Dosis", sans-serif;
    font-size: 100px;
    margin-bottom: 0px;
    font-weight: 800;
  }

  h3 {
    text-align: center;
    color: #fff;
    font-size: 19px;
    line-height: 23px;
    max-width: 330px;
    margin: 0px auto 30px auto;
    font-family: "Dosis", sans-serif;
    font-weight: 400;
  }

  h3 span {
    position: relative;
    width: 65px;
    display: inline-block;
  }

  h3 span:after {
    content: "";
    border-bottom: 3px solid #ffbb39;
    position: absolute;
    left: 0;
    top: 43%;
    width: 100%;
  }

  .stars {
    animation: sparkle 1.6s infinite ease-in-out alternate;
  }

  @keyframes sparkle {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }

  #astronaut {
    width: 43px;
    position: absolute;
    right: 20px;
    top: 210px;
    animation: spin 4.5s infinite linear;
  }

  @keyframes spin {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 75px;
    }
    h3 {
      font-size: 16px;
      width: 200px;
      margin: 0 auto;
      line-height: 23px;
    }
    h3 span {
      width: 60px;
    }
    #astronaut {
      width: 35px;
      right: 40px;
      top: 170px;
    }
  }

  .saturn,
  .saturn-2,
  .hover {
    animation: hover 2s infinite ease-in-out alternate;
  }

  @keyframes hover {
    0% {
      transform: translateY(3px);
    }
    100% {
      transform: translateY(-3px);
    }
  }

  .home-btn {
    color: #fff !important;
    border: 1px solid #fff !important;
  }
}
