@import "~antd/dist/antd.less";

@font-face {
  font-family: "Product Sans";
  src: url("assets/fonts/ProductSans-Regular.woff") format("woff");
}

body {
  margin: 0;
  font-family: "Product Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-select-selector {
  min-width: 171px;
}

.splash-container {
  height: 360px;
  justify-content: center;
  text-align: center;
  @media only screen and (max-width: 640px) {
    height: 300px;
    text-align: left;
  }
  padding: 0rem 1rem;

  .splash-title {
    font-size: 3rem;
    text-align: center;
    justify-content: center;
    @media only screen and (max-width: 640px) {
      font-size: 2rem;
      justify-content: left;
      text-align: left;
    }

    .pool-detail-splash {
      @media only screen and (max-width: 640px) {
        font-size: 1rem;
      }
    }
    display: flex;
    font-style: normal;
    font-weight: bold;
    margin-bottom: 0;
  }

  .splash-sub-title {
    text-align: center;
    justify-content: center;
    @media only screen and (max-width: 640px) {
      text-align: left;
      justify-content: left;
    }
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
  }
}
